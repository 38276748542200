.services {
  margin-top:20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 30px;
  grid-template-rows: auto;

  @media (max-width:500px) {
    grid-template-columns: repeat(1,1fr);
  }
}

.pod {
  display: block;
  max-width: 320px;
  box-shadow: 0 0px 8px 0 #c1c1c1;
  padding: 20px;
  border-radius: 4px;
  
  @media (max-width:500px) {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: calc(100% + 40px);
    left: -20px;
    position: relative;
    top: -20px;
    margin-bottom: -10px;
    border-radius: 4px 4px 0 0;
  }

  p:first-of-type {
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
  }

  p:first-letter {
    text-transform: capitalize;
  }

  a {
    position: relative;
    font-size: 18px;
    text-decoration: none;

    span {
      position: absolute;
      text-indent: -9000px;
      clip-path: circle(0);
    }
  }
}