@import 'vars';

html, body {
  height: 100%;
}

#root {
  box-sizing: border-box;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

body {
  margin: 0;
  font-family: $font, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clearfix:after {
  content: ".";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.full-width {
  display: block;
  width: 100%;
}

.grid {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.gird-12 {
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 1024px) {
  .grid-12 {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }
}

.text-center {
  text-align: center;
}

.banner {
  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
}

.content-cont {
  > .grid {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  h1 {
    margin-bottom: 50px;
  }

  h2 {
    margin-bottom: 30px;
  }

  h2 + * + h2 {
    margin-top: 60px;
  }
}

footer {
    background-color: map-get($colors,'primary');
    padding-top: 1px;
    padding-bottom: 1px;
    color: white;
    text-transform: uppercase;
}