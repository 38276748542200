@import 'vars';

.contact-form {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 20px;

  .hp {
    opacity: 0;
    position: absolute;
    text-indent: -9000px;
    clip-path: circle(0);
    z-index: -1;
  }

  span {
    position:absolute;
    opacity: 0;
    text-indent: -9000px;
  }
  label {
    width: 50%;
    padding: 15px 10px;
    box-sizing: border-box;
  }
  @media (max-width: 400px) {
    label {
      width: 100%;
    }
  }
  & label:last-of-type {
    width: 100%;
    padding: 10px;
  }

  input:not([type="submit"]),textarea {
    width: 100%;
    padding: 10px 10px;
    font-family: $font;
    font-size: 16px;
    box-sizing: border-box;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
  }
  textarea {
    height: 150px;
  }

  ::placeholder {
    font-family: $font;
    font-size: 16px;
  }

  input[type="submit"] {
    width: 150px;
    display: block;
    padding: 5px;
    background: map-get($colors,'secondary');
    border: none;
    line-height: 1.6;
    text-transform: capitalize;
    color: white;
    font-weight: 600;
    margin: 0 auto;
    font-family: $font;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;

    &:hover,&:focus,&:active {
      outline: none;
      box-shadow: 0 2px 4px 2px #868686;
    }
  }

  .success {
    display: none;
    background: white;
    font-size: 30px;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    &.show {
      display: flex;
    }
  }
}