@import 'vars';

.main-header {
  border-bottom: 1px solid #0E2773;
  position: sticky;
  width: 100%;
  z-index: 100;
  top: 0;
  background: white;
  box-shadow: 0 0 10px 0 #c1c1c1;

  * {
    transition: all 200ms ease-in;
  }

  &.sticky {
    .top-nav {
      padding-top: 5px;
      padding-bottom: 5px;


      .cta-button {
        font-size: 14px;
      }
    }
    .header {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    a.site-logo {
      max-width: 70px;

      @media (max-width: 640px) {
        max-width: 50px;
      }

      .site-logo {
        display: block;

        &.logo-text {
          display: none;
        }
      }

      span {
        display: none;
      }
    }
  }
}

.header {
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  
  nav {
    display: flex;
    align-items: center;
    ul {
      display: flex;
      flex-display: row;
      padding: 0;

      @media (max-width: 1024px) {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    li {
      list-style: none;
      text-transform: uppercase;

      &:last-child a {
        padding-right: 5px;
      }
    }
    a {
      display: block;
      color: #0E2773;
      font-size: 1.2rem;
      font-weight: 400;
      text-decoration: none;
      padding: 5px 20px;
      position: relative;

      &:hover:after,&.active:after {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% - 40px);
        height: 2px;
        background: #0E2773;

      }

      @media (max-width: 1024px) {
        padding: 5px 10px;
        font-weight: 600;
        font-size: 1rem;

        &:hover:after,&.active:after {
          width: calc(100% - 20px);
        }
      }
    }
  }
}

.top-nav {
  background: map-get($colors, 'primary');
  padding-top: 10px;
  padding-bottom: 10px;

  a {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    margin-right: 20px;
    text-decoration: none;
    padding: 5px;
    &:last-of-type {
      margin-right: 0;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .cta-button {
    display: block;
    float: right;
    border: none;
    background: map-get($colors, 'secondary');
    padding: 5px 10px;
    color: white;
    font-size: 18px;
    font-family: $font;
    line-height: 1.6;
    cursor: pointer;
    border-radius: 2px;

    &:focus, &:active, &:hover {
      outline: 1px solid white;
    }
  }
}

a.site-logo {
  display: block;
  max-width: 100px;
  text-decoration: none;

  @media (max-width: 1024px) {
    max-width: 70px;
  }

  .site-logo {
    display: none;

    &.logo-text {
      display: block;
    }
  }



    span {
      display: block;
      color: map-get($colors, 'primary');
      text-transform: capitalize;
      font-size: 15px;
      text-align: center;
    }

}

img.site-logo {
   width: 100%;
}