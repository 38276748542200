$colors: (
        "primary":#0D1440,
        "secondary": #BF1736);

/* Color Theme Swatches in Hex */
$TRIANGLED---Immersive-Light-Installation-1-hex: #BF1736;
$TRIANGLED---Immersive-Light-Installation-2-hex: #0D1440;
$TRIANGLED---Immersive-Light-Installation-3-hex: #1438A6;
$TRIANGLED---Immersive-Light-Installation-4-hex: #0E2773;
$TRIANGLED---Immersive-Light-Installation-5-hex: #1455D9;


$font: 'Source Sans Pro', sans-serif;